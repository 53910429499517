import { useProductsStore } from '~/store/productsStore.js'
import { useFilterStore } from '~/store/filterStore'

// should be used together with shopDiamondMiddleware
export function useDiamonds() {
  const productStore = useProductsStore()
  const filterStore = useFilterStore()
  const route = useRoute()

  const { $isFancy, $isPair, $isGemstone, $isDiamond, $isLab } = useShop()
  let isGetDiamondCalled = false

  const beforeDiamondListFetch = (route) => {
    if (route.query.stoneType !== 2) {
      delete route.query['gemType']
    }

    if (route.query.stoneType == 2) {
      route.query['min-carat'] = '0.50'

      if (!route.query.gemType || route.query.gemType == 366 || route.query.gemType == 371) {
        route.query.gemClarity = [13, 12]
      } else {
        route.query.gemClarity = []
      }

      filterStore.set({ type: 'gemClarity', value: route.query.gemClarity })
    }

    if (route.query.stoneType == 3) {
      route.query['min-carat'] = '0.40'
      route.query.clarity = [41, 40, 44, 39, 38, 47, 266]
      route.query.cuts = [57, 56]
    }
  }

  // use instead of $root.$emit('getDiamonds',
  const getDiamond = async (route, page = 1) => {
    if (isGetDiamondCalled) return // can be called only once per route/reload
    isGetDiamondCalled = true

    await productStore.getDiamonds({
      ...route.query,
      query: {
        ...diamondPreferences(),
        ...route.query,
        ...filterStore.getQuery(false),
      },
      page,
    })
  }

  // use instead of $root.$emit('addToShoppingBag
  const addToShoppingBag = (diamonds) => {
    if (!diamonds) {
      diamonds = route.params.opened // this is used in child params in legacy need to check
    }

    const item = $isFancy.value
      ? { item: -3, subcategory: 28 }
      : $isPair.value
        ? { item: -2, subcategory: 15 }
        : $isGemstone.value
          ? { item: -4, subcategory: 48 }
          : { item: -1, subcategory: 14 }

    if (route.query.quickShipping == 1) {
      ga_event_quick_init('quick x non quick', 'add loose stone to bag with quick shipping')
    } else {
      ga_event_quick_init('quick x non quick', 'add loose stone to bag without quick shipping')
    }

    productStore.addDiamondToShoppingBag({
      ...item,
      shape: diamonds[0].ShapeId,
      diamonds: diamonds.map((d) => d.Code),
      query: route.query,
    })
  }

  const diamondPageOnMount = () => {
    gglTrack('DiamondPage')
    if ($isDiamond.value && $isFancy.value && !$isLab.value && !$isGemstone.value && !$isPair.value) {
      // this.stoneType = null;
      filterStore.toggle({
        type: 'stoneType',
        option: null,
      })
    }

    beforeDiamondListFetch(route)
  }

  return {
    getDiamond,
    addToShoppingBag,
    diamondPageOnMount,
  }
}
